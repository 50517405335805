<template>
  <div class="emphasis">
    <h2>Page Not found
     <span class="big-emoji"> 🧐</span>
    </h2>
    <div class="left">
      <p>
         I was not able to find the page you were looking for.
      </p>
      <p class="desc">
         There is no such page found on this site.
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Skills',
}
</script>

<style scoped>
.reader {
  width: 100%;
  padding: 30px 30px;
  border-radius: 0 0 2rem 0;
}

h2 {
  margin-bottom: 50px;
}
.emphasis {
  text-align: center;
  font-size: 24px;
}

.desc {
  font-size: 18px;
}

.left {
  text-align: left;
  margin-left: 50px;
}

.big-emoji {
  font-size: 50px;
}

</style>
